import React, { useState, useEffect } from 'react';
import './Home.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeModalIndex, setActiveModalIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      // Disable scrolling on the body when the modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling on the body when the modal is closed
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  const openModal = (index) => {
    console.log("Opening modal for index:", index);
    setIsModalOpen(true);
    setActiveModalIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveModalIndex(null);
  };

  const servicesData = [
    {
      title: t('services.home'),
      content: 
      <div className="flex">
        <div className="services1-img1 services-img"/>
        <div className='modal-text'>
          <h1>{t('services.home')}</h1>
          <li>{t('services.reformas')}</li>
          <li>{t('services.modificacion')}</li>
          <li>{t('services.reparacion')}</li>
          <li>{t('services.instalaciones')}</li>
          <li>{t('services.domoticas')}</li>
        </div>
      </div>,
    },
    {
      title: t('services.comunidades'),
      content:       
      <div className="flex">
        <div className="services2-img1 services-img"/>
        <div className='modal-text'>
          <h1>{t('services.comunidades')}</h1>
          <li>{t('services.mantenimiento')}</li>
          <li>{t('services.reparacion')}</li>
          <li>{t('services.renovacion')}</li>
          <li>{t('services.instalaciones')}</li>
          <li>{t('services.domoticas')}</li>
        </div>
      </div>,
    },
    // Add more services data here
  ];

  return (
    <div className="homePage">
      <section className="introSection">
        <h1 className="slogan">{t('slogan')}</h1>
        <p className="companyLargeIntro">{t('company.intro')}</p>
        <Link to="/contact">
          <button className="contactButton">{t('contact.button')}</button>
        </Link>
      </section>

      <section className="imageTransitions">
        <div
          className="imageTransitionContainer1"
          style={{ transform: `translateY(-${scrollPosition * 0.1}px)` }}
        />
        <div
          className="imageTransitionContainer2"
          style={{ transform: `translateY(-${scrollPosition * 0.8}px)` }}
        />
      </section>

      <div className="anchorBox" id="servicios"></div>

      <section className="servicios">
        <h2>{t('servicios.title')}</h2>

        <div className="grid">
          {servicesData.map((service, index) => (
            <div key={index} className="card">
              <h3>{service.title}</h3>
              <button className="open-modal-btn" onClick={() => openModal(index)}>
                {t('button.saberMas')}
              </button>
            </div>
          ))}
        </div>

        {isModalOpen && activeModalIndex !== null && (
          <div className="modal-container open">
            <div className="modal-content">
              <button className="close-modal-btn" onClick={closeModal}>X</button>
              <div className="modal-text">
                <p>{servicesData[activeModalIndex].content}</p>
              </div>
            </div>
          </div>
        )}
      </section>

      <div className="InfoFlashcards">
        <section className="flashcardBox flexFlashcardBox WWUbox">
          <div className="generalCardSettings proyectosBg">
            <div className='overlay-content'>
              <h2 dangerouslySetInnerHTML={{ __html: t('proyectos.title') }} />
              <Link to="/proyectos">
                <button>{t('proyectos.verProyectos')}</button>
              </Link>
            </div>
          </div>
        </section>

        <section className="flashcardBox flexFlashcardBox">
          <div className="generalCardSettings workBg">
            <div>
              <h2 dangerouslySetInnerHTML={{ __html: t('contacto.pidePresupuesto') }} />
              <Link to="/contact">
                <button>{t('contacto.button')}</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;