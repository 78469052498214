import React, { useState, useEffect } from 'react';
import imagesData from './images.json';
import './proyectos.css'; // Importa el archivo CSS
import { useTranslation } from 'react-i18next';

const Proyectos = () => {
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Todos'); // Iniciar con la categoría directa
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    // Simulando la obtención de imágenes desde un archivo JSON
    setImages(imagesData.images);
  }, []);

  useEffect(() => {
    // Alternar la propiedad overflow del body cuando cambia showModal
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    // Cerrar el modal al cambiar de categoría
    setShowModal(false);
  };

  const openModal = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  const viewFullscreen = () => {
    if (modalImage) {
      const newWindow = window.open(modalImage.url, '_blank');
      if (newWindow) newWindow.document.title = modalImage.id;
    }
  };

  // Filtrar las imágenes según la categoría seleccionada
  const filteredImages = selectedCategory === 'Todos'
    ? images
    : images.filter((image) => image.category === selectedCategory);

  // Cambiar el idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedCategory('Todos'); // Asegurarse de que 'Todos' se establezca correctamente
  };

  return (
    <div className="galleryPage">
      <div className="filterButtons">
        <button
          className={`filterButton ${selectedCategory === 'Todos' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('Todos')}
        >
          {t('proyectos.categorias.todos')}
        </button>
        <button
          className={`filterButton ${selectedCategory === 'Cocinas' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('Cocinas')}
        >
          {t('proyectos.categorias.cocinas')}
        </button>
        <button
          className={`filterButton ${selectedCategory === 'Baños' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('Baños')}
        >
          {t('proyectos.categorias.banos')}
        </button>
        <button
          className={`filterButton ${selectedCategory === 'Oficinas' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('Oficinas')}
        >
          {t('proyectos.categorias.oficinas')}
        </button>
        <button
          className={`filterButton ${selectedCategory === 'Pisos' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('Pisos')}
        >
          {t('proyectos.categorias.pisos')}
        </button>
        <button
          className={`filterButton ${selectedCategory === 'Comunidades' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('Comunidades')}
        >
          {t('proyectos.categorias.comunidades')}
        </button>
        <button
          className={`filterButton ${selectedCategory === 'EN' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('EN')}
        >
          {t('proyectos.categorias.en')}
        </button>
      </div>
      <div className="gallerySection">
        {filteredImages.map((image) => (
          <div
            key={image.id}
            className="galleryItem"
            style={{ backgroundImage: `url(${image.url})` }}
            onClick={() => openModal(image)}
          ></div>
        ))}
      </div>

      {showModal && modalImage && (
        <div className="modalBackdrop" onClick={closeModal}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <button className="closeButton" onClick={closeModal}>
              X
            </button>
            <div className="modalCollabContainer">
              <div className="logo-icon" />
              <div className="modalImageContainer">
                <img className="modalImage" src={modalImage.url} alt="" />
                <button className="fullscreenButton" onClick={viewFullscreen}>
                  ⤢
                </button>
              </div>
              <div className="imageTitle">{modalImage.title}</div>
              {modalImage.collab && (
                <img className="imageCollab" src={modalImage.collab} alt="Colaboración" />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Proyectos;