import React from 'react';
import './privacy.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function Privacy() {
  const { t } = useTranslation();

  return (
    <section className="section-hero header-text-center">
      <div className="section-content privacy-container">
        <h1 className="privacy-title">{t('privacy.title')}</h1>
        <h3 className="privacy-subtitle">{t('privacy.updated')}</h3>
        <div className="privacy-content">
          <p>{t('privacy.content')}</p>
        </div>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.basicInfo.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <h3>{t('privacy.basicInfo.responsible.title')}</h3>
              <p><strong>{t('privacy.basicInfo.responsible.entity')}</strong> {t('privacy.basicInfo.responsible.name')}</p>
              <p><strong>{t('privacy.basicInfo.responsible.cif')}</strong> {t('privacy.basicInfo.responsible.taxNumber')}</p>
              <p><strong>{t('privacy.basicInfo.responsible.address')}</strong> {t('privacy.basicInfo.responsible.addressDetails')}</p>
              <p><strong>{t('privacy.basicInfo.responsible.phone')}</strong> {t('privacy.basicInfo.responsible.phoneNumber')}</p>
              <p><strong>{t('privacy.basicInfo.responsible.email')}</strong> <a href={`mailto:${t('privacy.basicInfo.responsible.emailAddress')}`}>{t('privacy.basicInfo.responsible.emailAddress')}</a></p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.dataProcessingPurpose.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('privacy.dataProcessingPurpose.content')}</p>
              <p>{t('privacy.dataProcessingPurpose.details')}</p>
              <ul>
                <li>{t('privacy.dataProcessingPurpose.listItem1')}</li>
                <li>{t('privacy.dataProcessingPurpose.listItem2')}</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.communication.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('privacy.communication.content')}</p>
              <p>{t('privacy.communication.revokeConsent')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.dataRetention.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('privacy.dataRetention.content')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.legalBasis.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('privacy.legalBasis.content')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.dataRecipients.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('privacy.dataRecipients.content')}</p>
              <p>{t('privacy.dataRecipients.publicAuthorities')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('privacy.userRights.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('privacy.userRights.content1')}</p>
              <p>{t('privacy.userRights.content2')}</p>
              <p>{t('privacy.userRights.content3')}</p>
              <p>{t('privacy.userRights.contact')}</p>
              <p>{t('privacy.userRights.contactDetails')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
}

export default Privacy;