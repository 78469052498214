import React, { useEffect } from 'react';
import L from 'leaflet'; // Import Leaflet library
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import './contacto.css'; // Example CSS import
import { useTranslation } from 'react-i18next';

function Contacto() {
  const { t } = useTranslation();

  useEffect(() => {
    // Initialize Leaflet map
    const map = L.map('map-container').setView([41.4042098598875, 2.13933196763005], 15); // Example coordinates and zoom

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Add marker and popup if needed
    const marker = L.marker([41.4042098598875, 2.13933196763005]).addTo(map)
      .bindPopup(t('contacto.mapPopup'))
      .openPopup();

    // Cleanup function to remove the map
    return () => {
      map.remove();
    };
  }, [t]);

  return (
    <div className="contacto-container">
      <div className="contacto">
        <h1>{t('contacto.title')}</h1>
        <ul>
          <li className="contacto-item">
            <div className="contacto-icon loc-icon" />
            <address className="contacto-text">{t('contacto.address')}</address>
          </li>

          <li className="contacto-item">
            <div className="contacto-icon time-schedule-icon" />
            <div className="time-schedule-text">
              <p>{t('contacto.scheduleWeekdays')}</p>
              <p>{t('contacto.scheduleWeekend')}</p>
            </div>
          </li>

          <li className="contacto-item">
            <div className="contacto-icon phone-icon" />
            <div className="phone-text">
              <a href='tel:932125499'>{t('contacto.phone')}</a>
            </div>
          </li>

          <li className="contacto-item">
            <div className="contacto-icon email-icon" />
            <div className="email-text">
              <a href='mailto:info@instalecjordan.com'>{t('contacto.email')}</a>
            </div>
          </li>

          <li className="contacto-item map-container" id="map-container">
            {/* Map will be rendered here */}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contacto;
