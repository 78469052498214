import React from 'react';
import './cookies.css';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Cookies() {
  const { t } = useTranslation();

  return (
    <section className="section-hero header-text-center">
      <div className="section-content privacy-container">
        <h1 className="privacy-title">{t('cookies.title')}</h1>
        <h3 className="privacy-subtitle">{t('cookies.updated')}</h3>
        <div className="privacy-content">
          <p>{t('cookies.content')}</p>
        </div>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question1')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer1')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question2')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer2')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question3')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer3')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question4')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer4')}</p>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>{t('cookies.table.header.type')}</th>
                    <th>{t('cookies.table.header.name')}</th>
                    <th>{t('cookies.table.header.description')}</th>
                    <th>{t('cookies.table.header.ownership')}</th>
                    <th>{t('cookies.table.header.expiration')}</th>
                    <th>{t('cookies.table.header.international_transfer')}</th>
                  </tr>
                </thead>
                <tbody>
                  {t('cookies.table.rows', { returnObjects: true }).map((row, index) => (
                    <tr key={index}>
                      <td>{row.type}</td>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>{row.ownership}</td>
                      <td>{row.expiration}</td>
                      <td>{row.international_transfer}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question5')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer5')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question6')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer6')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question7')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer7')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">{t('cookies.question8')}</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>{t('cookies.answer8')}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    </section>
  );
}

export default Cookies;