// Footer.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './footer.css';
import { useTranslation } from 'react-i18next';


function Footer() {
  const { t, i18n } = useTranslation();
  const [showCookiesConfig, setShowCookiesConfig] = useState(false);
  const [cookiesImprescindiblesChecked, setCookiesImprescindiblesChecked] = useState(false);
  const [cookiesEstadisticaChecked, setCookiesEstadisticaChecked] = useState(false);

  useEffect(() => {
    const cookieStatus = getCookie('-CStatus');

    if (cookieStatus === '200') {
      setCookiesImprescindiblesChecked(true);
      setCookiesEstadisticaChecked(true);
    } else {
      setCookiesImprescindiblesChecked(true); // Only set the first checkbox checked if not allowed
    }
  }, []);

  const handleCookiesConfigClick = () => {
    setShowCookiesConfig(!showCookiesConfig);
  }

  const handleSaveCookiesConfig = () => {
    if (cookiesImprescindiblesChecked && cookiesEstadisticaChecked) {
      setCookie('-CStatus', '200', 365);
    } else {
      setCookie('-CStatus', '400', 365);
    }
    setShowCookiesConfig(false);
  }

  const handleImprescindiblesChange = (e) => {
    if (!e.target.checked) {
      // If trying to uncheck, prevent it
      e.preventDefault();
      return;
    }
    setCookiesImprescindiblesChecked(true);
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    
    // Check if the cookie already exists
    const existingCookie = getCookie(name);
    if (existingCookie) {
      document.cookie = `${name}=${value}; ${expires}; path=/`;
    } else {
      document.cookie = `${name}=${value}; ${expires}; path=/`;
    }
  };

  return (
    <div>
      <footer>
        <div className="flex cpyright-legal">
          <p>{t('footer.copyright')}</p>
          <p> 
            <Link to="/legal/privacidad" className="footer-link">{t('footer.privacyPolicy')}</Link> | <Link to="/legal/cookies" className="footer-link">{t('footer.cookiesUsage')}</Link>
          </p>
        </div>
        <div>
          <button className="CookiesConfig" onClick={handleCookiesConfigClick}>{t('footer.manageCookies')}</button>
        </div>
      </footer>

      {showCookiesConfig && (
        <div className="CookiesConfigOverlay">
          <div className="CookiesConfigModal">
            <h2>{t('cookiesConfig.title')}</h2>
            <p>{t('cookiesConfig.readMore')} <Link to="/legal/cookies" className="footer-link">{t('cookiesConfig.cookiesPolicy')}</Link></p>
            
            <div className="mt-8">
              <label className="flex items-start gap-5xs cursor-pointer">
                <div className="relative inline-flex align-middle top-[0.25rem]">
                  <input
                    type="checkbox"
                    id="cookiesImprescindibles"
                    name="cookiesImprescindibles"
                    className="peer cursor-pointer appearance-none focus:focus-visible:outline focus:focus-visible:outline-1 focus:focus-visible:outline-offset-2 w-[0.75rem] h-[0.75rem] border border-btn-ring rounded-[0.125rem] checked:border-btn-secondary-label checked:bg-btn-secondary-label border-input-border--disabled bg-input bg-radio-base"
                    checked={cookiesImprescindiblesChecked}
                    onChange={handleImprescindiblesChange}
                    disabled
                  />
                </div>
                <span className="text-caption select-none max-w-prose text-input-border--filled">{t('cookiesConfig.essentialCookies')}</span>
              </label>

              <div className="mt-4">
                <label className="flex items-start gap-5xs cursor-pointer">
                  <div className="relative inline-flex align-middle top-[0.25rem]">
                    <input
                      type="checkbox"
                      id="cookiesEstadistica"
                      name="cookiesEstadistica"
                      className="peer cursor-pointer appearance-none focus:focus-visible:outline focus:focus-visible:outline-1 focus:focus-visible:outline-offset-2 w-[0.75rem] h-[0.75rem] border border-btn-ring rounded-[0.125rem] checked:border-btn-secondary-label checked:bg-btn-secondary-label"
                      checked={cookiesEstadisticaChecked}
                      onChange={(e) => setCookiesEstadisticaChecked(e.target.checked)}
                      disabled={!cookiesImprescindiblesChecked}
                    />
                  </div>
                  <span className="text-caption select-none max-w-prose">{t('cookiesConfig.statisticsCookies')}</span>
                </label>
              </div>

              <div className="mt-8 flex w-full justify-between items-center">
                <button type="button" className="w-full bg-btn-primary-base transition duration-200 ease-curve-a bg-btn-primary-base text-btn-primary-label rounded-full px-xs text-nowrap min-h-button flex items-center justify-center gap-[0.3em] text-cta hover:bg-btn-primary-base--hover transition duration-200 ease-curve-a bg-btn-primary-base text-btn-primary-label rounded-full px-xs text-nowrap min-h-button flex items-center justify-center gap-[0.3em] text-cta enabled:hover:bg-btn-primary-base--hover disabled:bg-btn-primary-base--disabled disabled:text-btn-primary-label--disabled" onClick={handleSaveCookiesConfig}>{t('cookiesConfig.saveButton')}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;