// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationCA from './locales/ca/translation.json';
import translationES from './locales/es/translation.json';

const resources = {
  ca: {
    translation: translationCA,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next)  // Initialize react-i18next
  .init({
    resources,
    lng: 'es',             // Set default language to Spanish
    fallbackLng: 'ca',     // Fallback language if translation is missing
    interpolation: {
      escapeValue: false,  // Prevent HTML escaping
    },
  });

export default i18n;